<template>
  <button
    class="
      terminal-button terminal-button-red
      w-5
      h-5
      cursor-pointer
      flex
      content-center
      items-center
    "
  >
    <span class="self-center mx-auto text-xs">X</span>
  </button>
</template>

<script>
export default {}
</script>

<style>
.terminal-button-red {
  background-color: #fd5959;
}
</style>
